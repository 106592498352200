<template>
    <v-container fluid class="mx-auto">
        <v-row>
            <v-col class="d-flex justify-end" cols="12">
                <v-switch v-model="paydrc" inset label="PayDRC Database"></v-switch>
            </v-col>
        </v-row>
        <v-row class="mx-auto mt-2">
            <v-col cols="12" lg="6" md="6">
                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="dates"
                    transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="dates" label="selectionne une date ou une intervalle de date"
                            prepend-icon="mdi-calendar" readonly rounded solo v-bind="attrs" v-on="on" clearable dense>
                        </v-text-field>
                    </template>
                    <v-date-picker range v-model="dates" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="$refs.menu.save(dates)">OK</v-btn>
                    </v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" lg="3" md="3">
                <v-btn block rounded @click="search" :loading="loading">
                    <v-icon>mdi-magnify</v-icon>&nbsp;
                    Search
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="col-lg-2 mt-2 float-lg-left d-flex justify-center">
                <v-btn outlined rounded color="green">
                    <download-csv :data="items"></download-csv>
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="mt-10">
            <v-col>
                <v-card rounded="xl">
                    <v-card-text>
                        <v-data-table :headers="headers" :items="items" :search="searchDetail">
                            <template v-slot:top>
                                <v-text-field solo rounded v-model="searchDetail"
                                    label="Search a merchant Code/merchant ID/Customer Details" clearable class="mx-4 pt-4"
                                    append-icon="mdi-magnify"></v-text-field>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-5">
            <v-col cols="12" md="6">
                <v-menu ref="menuBalance" v-model="menuBalance" :close-on-content-click="false"
                    :return-value.sync="balanceDate" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="balanceDate"
                            label="selectionne une date pour avoir la balance orange de 1xbet & FreshPay"
                            prepend-icon="mdi-calendar" readonly rounded solo v-bind="attrs" v-on="on" clearable dense>
                        </v-text-field>
                    </template>
                    <v-date-picker v-model="balanceDate" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menuBalance = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="$refs.menuBalance.save(balanceDate)">OK</v-btn>
                    </v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="4">
                <v-btn block solo rounded @click="getOrangeBalance" :loading="loadingBalance">check Balance</v-btn>
            </v-col>
        </v-row>
        <v-row class="mb-5">
            <v-col cols="12">
                <v-card rounded="xl">
                    <v-card-title class="d-flex justify-center" v-show="balanceDate">Balances du {{ balanceDate }}
                    </v-card-title>
                    <v-card-text class="text-center text-h6">
                        FreshPay Balance USD : {{ orangeBalance.fpusd }} <br>
                        FreshPay Balance CDF : {{ orangeBalance.fpcdf }} <br>
                        1xbet Balance USD : {{ orangeBalance.oxusd }} <br>
                        1xbet Balance CDF : {{ orangeBalance.oxcdf }} <br>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
import services from "../services/services"

export default {
    data: () => ({
        orangeBalance: {
            "fpusd": 0,
            "fpcdf": 0,
            "oxusd": 0,
            "oxcdf": 0
        },
        loadingBalance: false,
        balanceDate: "",
        menuBalance: false,
        dialog: false,
        dialogInfo: false,
        dates: [],
        paydrc: true,
        menu: false,
        searchDetail: "",
        loading: false,
        headers: [
            { text: "Transaction ID", value: "transaction_id", align: "center" },
            { text: "Transaction Reference", value: "transaction_reference", align: "center" },
            { text: "Merchant Reference", value: "merchant_reference", align: "center" },
            { text: "Merchant", value: "merchant", align: "center" },
            { text: "Telco Reference", value: "operator_reference", align: "center" },
            { text: "Telecom Operator", value: "operator", filterable: false, align: "center" },
            { text: "Customer Details", value: "customer_details", align: "center" },
            { text: "Amount", value: "amount", filterable: false, align: "center" },
            { text: "Currency", value: "currency", filterable: false, align: "center" },
            { text: "Action", value: "action", filterable: false, align: "center" },
            { text: "Status", value: "status", filterable: false, align: "center" },
            { text: "Created At", value: "created_at", filterable: false, align: "center" },
            { text: "Updated At", value: "updated_at", filterable: false, align: "center" },
            { text: "Status Description", value: "description", filterable: false, align: "center" },
        ],
        items: []
    }),
    methods: {
        async getOrangeBalance() {
            this.loadingBalance = true
            const data = {
                "date": this.balanceDate
            }
            const balance = await services.get_orange_balance(data)

            if (balance.length == 0) {
                this.orangeBalance.fpcdf = 0
                this.orangeBalance.fpusd = 0
                this.orangeBalance.oxcdf = 0
                this.orangeBalance.oxusd = 0
            }
            else {

                this.orangeBalance.fpcdf = balance[0].freshpay_cdf_balance
                this.orangeBalance.fpusd = balance[0].freshpay_usd_balance
                this.orangeBalance.oxcdf = balance[1].oxbet_cdf_balance
                this.orangeBalance.oxusd = balance[1].oxbet_usd_balance
            }

            console.log(balance);
            this.loadingBalance = false
        },
        async search() {
            this.loading = true
            if (this.dates.length === 1) {
                const data = {
                    "startDate": this.dates[0],
                    "endDate": this.dates[0],
                    "paydrc": this.paydrc
                }
                const response = await services.transactions_history(data)
                this.items = response.transactions
                this.loading = false
            }
            else {
                const data = {
                    "startDate": this.dates[0],
                    "endDate": this.dates[1],
                    "paydrc": this.paydrc
                }
                const response = await services.transactions_history(data)
                this.items = response.transactions
                this.loading = false
            }
        },
    }
}
</script>


<style scoped>
</style>